/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

/*==================== VARIABLES CSS ====================*/
:root {
  --header-height: 3rem;

  /*========== Colors ==========*/
  /* Change favorite color */
  --hue-color: 235; /*Purple 250 - Green 142 - Blue 230 - Pink 340*/

  /* HSL color mode */
  --first-color: hsl(var(--hue-color), 69%, 61%);
  --first-color-second: hsl(var(--hue-color), 69%, 61%);
  --first-color-alt: hsl(var(--hue-color), 57%, 53%);
  --first-color-lighter: hsl(var(--hue-color), 92%, 85%);
  --title-color: hsl(var(--hue-color), 8%, 15%);
  --text-color: hsl(var(--hue-color), 8%, 45%);
  --text-color-light: hsl(var(--hue-color), 8%, 65%);
  --input-color: hsl(var(--hue-color), 70%, 96%);
  --body-color: hsl(var(--hue-color), 60%, 99%);
  --container-color: #fff;
  --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
  --scroll-thumb-color: hsl(var(--hue-color), 12%, 80%);
  --drawer-container-color: hsl(var(--hue-color), 60%, 99%);

  /*========== Font and typography ==========*/
  --body-font: "Poppins", sans-serif !important;

  /* .5rem = 8px, 1rem = 16px, 1.5rem = 24px ... */
  --big-font-size: 2rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== Margins Bottom ==========*/
  /* .25rem = 4px, .5rem = 8px, .75rem = 12px ... */
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 500;
  --z-modal: 1000;
}

/* Font size for large devices */
@media screen and (min-width: 968px) {
  :root {
    --big-font-size: 2.75rem;
    --h1-font-size: 2rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

@media screen and (min-width: 1200px) {
  :root {
    --big-font-size: 2.9rem;
  }
}

/*========== Variables Dark theme ==========*/
.body-div.dark-theme {
  --first-color-second: hsl(var(--hue-color), 30%, 8%);
  --title-color: hsl(var(--hue-color), 8%, 95%);
  --text-color: hsl(var(--hue-color), 8%, 75%);
  --input-color: hsl(var(--hue-color), 29%, 16%);
  --body-color: hsl(var(--hue-color), 28%, 10%);
  --container-color: hsl(var(--hue-color), 29%, 16%);
  --scroll-bar-color: hsl(var(--hue-color), 12%, 48%);
  --scroll-thumb-color: hsl(var(--hue-color), 12%, 36%);
  --drawer-container-color: hsl(var(--hue-color), 29%, 16%);
}

/*========== Button Dark/Light ==========*/
.nav__btns {
  display: flex;
  align-items: center;
}

.change-theme {
  font-size: 1.25 rem;
  color: var(--title-color);
  margin-right: var(--mb-1);
  cursor: pointer;
}

.change-theme:hover {
  color: var(--first-color);
}

/*==================== BASE ====================*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

html {
  scroll-behavior: smooth;
}

/* body {
  margin: 0 0 var(--header-height) 0;
  font-family: var(--body-font) !important;
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
} */

.body-div {
  margin: 0 0 var(--header-height) 0;
  font-family: var(--body-font) !important;
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
}

h1,
h2,
h3,
h4 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none !important;
}

img {
  max-width: 100%;
  height: auto;
}

/*==================== REUSABLE CSS CLASSES ====================*/
.section {
  padding: 4rem 0 2rem;
}

.section__title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-3);
}

.section__title,
.section__subtitle {
  text-align: center;
}

/*==================== LAYOUT ====================*/
.container {
  max-width: 992px;
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
  justify-self: center;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
  /* box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15); */
  /* border: 2px solid blue; */
}

/*==================== NAV ====================*/
.nav {
  /* max-width: 968px; */
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__logo,
.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
  /* z-index: 999 !important; */
}

.nav__logo:hover {
  color: var(--first-color);
}

.nav__toggle {
  font-size: 1.1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
  padding: 2px 0px;
}

.nav__toggle:hover {
  color: var(--first-color);
}

/* .nav__menu {
  display: none;
} */
/* .grid {
  display: grid;
  gap: 1.5rem;
} */

@media screen and (max-width: 767px) {
  .nav__menu {
    display: none;
  }

  .nav__drawer {
    display: block !important;
  }
}

.MuiTypography-root {
  font-family: var(--body-font) !important;
  /* color: var(--title-color) !important; */
}

.active-drawer-link {
  font-family: var(--body-font) !important;
  color: #fcfcfc !important;
}

.MuiListItemButton-root {
  color: var(--title-color) !important;
  border-radius: 0.5rem !important;
}

.MuiListItemButton-root.Mui-selected {
  background-color: var(--first-color) !important;
  color: var(--body-color) !important;
}

.MuiDrawer-paper {
  background-color: var(--drawer-container-color) !important;
}

.drawer--divider {
  background-color: var(--scroll-thumb-color) !important;
  color: var(--scroll-thumb-color) !important;
}

.nav__close {
  position: absolute;
  right: 1.3rem;
  bottom: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--first-color);
}

.nav__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.nav__link {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  font-family: var(--body-font) !important;
}

.nav__link:hover {
  color: var(--first-color);
}

.nav__icon {
  font-size: 1.2rem;
  size: "15";
}

.nav__close:hover {
  color: var(--first-color-alt);
}

/* show menu */
.show-menu {
  top: 0;
}

/* Active link */
.active-link {
  color: var(--first-color);
}

/* Change background header */
.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

/*==================== HOME ====================*/

.home__container {
  gap: 1rem;
}

.home__content {
  grid-template-columns: 0.5fr 3fr;
  padding-top: 2rem;
  align-items: center;
}

.home__social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}

.home__social-icon {
  font-size: 1.25rem;
  color: var(--first-color);
  cursor: pointer !important;
}

.home__social-icon:hover {
  color: var(--first-color-alt);
}

.home__blob {
  width: 200px;
  fill: var(--first-color);
}

.home__blob-img {
  width: 180px;
}

.home__data {
  grid-column: 1/3;
}

.home__title {
  font-size: var(--big-font-size);
}

.home__subtitle {
  font-size: var(--h3-font-size);
  color: var(--text-color);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-75);
}

.home__description {
  margin-bottom: var(--mb-2);
}

.home__scroll {
  display: block;
}

.home__scroll-button {
  color: var(--first-color);
  transition: 0.3s;
}

.home__scroll-button:hover {
  transform: translateY(0.25rem);
}

.home__scroll-mouse {
  font-size: 2rem;
}

.home__scroll-name {
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25);
}

.home__scroll-arrow {
  font-size: 1.25rem;
}

/*==================== BUTTONS ====================*/

.button {
  display: inline-block;
  background-color: var(--first-color);
  color: #fff;
  padding: 0.8rem;
  /* padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem; */
  border-radius: 0.5rem;
  font-weight: var(--font-medium);
  cursor: pointer;
}

.modal__github--btn {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  font-size: var(--small-font-size);
  font-weight: var(--normal-font-size);
}

.button:hover {
  background-color: var(--first-color-alt);
}

.button__icon {
  font-size: 1.25rem;
  margin-left: var(--mb-0-5);
  transition: 0.3s;
}

.button--white {
  background-color: #fff;
  color: var(--first-color);
}

.button--white:hover {
  background-color: #fff;
}

.button--flex {
  display: inline-flex;
  align-items: center;
}

/*==================== ABOUT ====================*/

/* .about__container {
  border: 2px solid red;
} */

.about__img {
  width: 220px;
  height: 100%;
  border-radius: 0.5rem;
  justify-self: center;
  object-fit: cover;
  /* align-self: center; */
}

.about__img--long {
  display: none;
}

.about__description {
  /* text-align: center; */
  /* font-size: var(--small-font-size); */
  margin-bottom: var(--mb-1);
}

.about__description:last-child {
  margin-bottom: var(--mb-2-5);
}

.about__buttons {
  display: flex;
  justify-content: center;
}

/*==================== SKILLS ====================*/
.skills_container {
  row-gap: 0;
}

.skills__header {
  display: flex;
  align-items: center;
  margin-bottom: var(--mb-1-5);
}

.skill_icon {
  color: var(--first-color);
  margin-right: var(--mb-0-75);
}

.skills__title {
  font-size: var(--h3-font-size);
}

.skills__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  grid-auto-rows: auto;
  height: auto;
  /* border: solid 2px red; */
}

.skill__image__wrap {
  /* border: solid 2px blue; */
  display: flex;
  justify-content: center;
}

.skills__grid img {
  height: 75px;
  width: 75px;
  justify-self: center !important;
}

.skills__content {
  padding: 0.2rem 0;
  /* margin-bottom: var(--mb-3); */
}

.skills__content:nth-child(1),
.skills__content:nth-child(2),
.skills__content:nth-child(3) {
  margin-bottom: var(--mb-2);
}
/* .skills{

} */
/*==================== QUALIFICATION ====================*/

.qualification {
  overflow-x: hidden;
}

.qualification__tabs {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: var(--mb-2);
}

.qualification__button {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  cursor: pointer;
}

.qualification__button:hover {
  color: var(--first-color);
}

.qualification__icon {
  font-size: 1.8rem;
  margin-right: var(--mb-0-25);
}

/* .qualification__content {
  display: block;
  justify-content: center;
} */

.qual__data {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 1.3rem;
}

.qualification__card {
  background-color: var(--container-color) !important;
  padding: 10px;
  display: flex;
  /* flex: 1; */
  flex-direction: column;
  border-radius: 4px !important;
  max-width: 560px;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.15));
  cursor: pointer;
}

.qual__title__wrapper {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.qualification__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.qual-card-icon {
  color: var(--text-color-light);
}

.qualification__subtitle {
  display: inline-block;
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-1);
}

.qual_bullet-list {
  font-size: var(--small-font-size);
  padding-left: 1.5rem;
  padding-right: 1rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.qualification__calender {
  font-size: var(--smaller-font-size);
  color: var(--text-color-light);
  display: flex;
  align-items: center;
}

.qualification__calender-icon {
  margin-right: 0.5rem !important;
}
@media screen and (max-width: 382px) {
  .qualification__calender-icon {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .qual__data .qualification__card::after {
    content: "";
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    top: 8px;
    left: -10px;
    transform: rotate(180deg);
    border-width: 8px 0 8px 10px;
    border-color: transparent transparent transparent var(--container-color);
  }

  [data-aos="fade-right"] {
    /* opacity: 0;
    transition-property: transform, opacity; */
    transform: translateX(100px) !important;
  }
  [data-aos="fade-right"].aos-animate {
    /* opacity: 1; */
    transform: translateX(0) !important;
  }

  .empty-div {
    display: none;
  }

  .qual__data {
    grid-template-columns: max-content 1fr;
    padding-bottom: 10px;
  }

  .qual__marker--col {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .qualification_card {
    grid-row: 1/2;
    grid-column: 2/2;
  }
}

@media screen and (min-width: 768px) {
  .nav__drawer {
    display: none !important;
  }
}

@media screen and (min-width: 769px) {
  .qual__data:nth-child(odd) .qualification__card::after {
    content: "";
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    top: 8px;
    right: -10px !important;
    border-width: 8px 0 8px 10px;
    border-color: transparent transparent transparent var(--container-color);
  }

  .qual__data:nth-child(even) .qualification__card::after {
    content: "";
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    top: 8px;
    left: -10px;
    transform: rotate(180deg);
    border-width: 8px 0 8px 10px;
    border-color: transparent transparent transparent var(--container-color);
  }
}

.qualification__rounder {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: var(--first-color);
  border-radius: 50%;
  margin-top: 10px !important;
}

.qualification__line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--first-color);
  transform: translate(6px, -7px);
}

.qualification [data-content] {
  display: none;
}

.qualification__active[data-content] {
  display: block;
  justify-content: center;
}

.qualification__button.qualification__active {
  color: var(--first-color);
}

.qual__data:last-child .qualification__line {
  display: none;
}

/*==================== projects ====================*/
.projects__container {
  overflow: initial !important;
  /* border: solid 2px red; */
}

.projects__content {
  padding: 0 1.5rem;
  grid-auto-rows: max-content 1fr; /* equal height for project data*/
  /* align-items: center; */
}

.projects__content-grid {
  display: none;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  row-gap: 3rem;
}

.projects__img {
  display: block;
  width: 265px;
  /* height: 100%; */
  border-radius: 0.5rem;
  justify-self: center;
  cursor: pointer;
}

.projects__data {
  display: flex;
  flex-direction: column;
  /* border: 2px solid red; */
}

.projects__title {
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-0-5);
}

.projects__description {
  margin-bottom: var(--mb-0-75);
}

.projects__button--div {
  display: flex;
  column-gap: 1rem;
  margin-top: auto;
  /* border: 2px solid red; */
}

/* .projects__button:hover .button__icon {
  transform: translateX(0.25rem);
} */

/* ================swiper slider ================= */

/* .swiper {
  overflow: initial !important;
} */
/* .swiper-wrapper{
  overflow: auto !important;
} */

.swiper-button-next::after,
.swiper-button-prev::after {
  color: var(--first-color);
  font-size: 1.2rem !important;
  font-weight: var(--font-semi-bold) !important;
}

.swiper-button-prev {
  left: -0.45rem !important;
  top: 45% !important;
}

.swiper-button-next {
  right: -0.45rem !important;
  top: 45% !important;
}

.swiper-pagination-bullet {
  background-color: var(--text-color) !important;
  opacity: 0.3 !important;
}

.swiper-pagination-bullet-active {
  background-color: var(--first-color) !important;
  opacity: 1 !important;
}

.swiper-pagination {
  position: relative !important;
  bottom: 0 !important;
  margin-top: 1.5rem;
  /* border: solid 2px blue; */
}

.project__modal--dialog {
  font-family: var(--body-font) !important;
  /* filter: blur(3px); */
}

.project__modal--dialog .MuiDialog-paper {
  background-color: var(--container-color) !important;
}

.project__modal--title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--body-font) !important;
  color: var(--title-color) !important;
}

.modal__close--icon {
  color: var(--first-color);
  margin-right: -6px;
  cursor: pointer;
}

.modal__close--icon:hover {
  color: var(--first-color-alt);
}

.modal__video--player {
  width: 100%;
  aspect-ratio: 16/9;
  max-height: 380px;
  margin: 0 auto;
  background-color: var(--text-color-light);
}

.modal__project--summary {
  font-family: var(--body-font) !important;
  /* font-size: var(--normal-font-size) !important; */
  font-weight: var(--font-medium) !important;
  margin-top: var(--mb-1-5) !important;
  margin-bottom: 0.2rem !important;
  color: var(--title-color) !important;
}

.modal__project--summary--text {
  font-family: var(--body-font) !important;
  font-size: var(--small-font-size) !important;
  margin-bottom: var(--mb-0-5) !important;
  /* text-align: justify; */
  color: var(--text-color) !important;
}

.modal__button-wrap {
  display: flex;
  column-gap: 1rem;
  /* padding-top: var(--mb-0-25); */
}

.modal__tech--stack {
  display: grid;
  grid-template-columns: repeat(auto-fit, 3rem);
  column-gap: 1rem;
  row-gap: 1rem;
  grid-auto-rows: auto;
  height: auto;
  margin-top: var(--mb-1) !important;
  margin-bottom: var(--mb-1);
}

.modal__tech--stack img {
  height: 3rem;
  width: 3rem;
}

/*==================== PROJECT IN MIND ====================*/

.project {
  text-align: center;
}

.project__bg {
  background-color: var(--first-color-second);
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.project__title {
  font-size: var(--h2-font-size);
  margin-bottom: var(--mb-0-75);
}

.project__description {
  margin-bottom: var(--mb-1-5);
}

.project__title,
.project__description {
  color: #fff;
}

.project__img {
  width: 290px;
  height: 100%;
  justify-self: center;
  align-self: center;
  margin-top: var(--mb-1);
}

/*==================== CONTACT ME ====================*/

.contact__container {
  row-gap: 3rem;
}

.contact__information {
  display: flex;
  margin-bottom: var(--mb-2);
  align-items: center;
}

.contact__icon {
  font-size: 2rem;
  color: var(--first-color);
  margin-right: var(--mb-0-75);
}

.contact__title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
}

.contact__subtitle {
  font-size: var(--small-font-size);
  color: var(--text-color-light);
}

.contact__content {
  background-color: var(--input-color);
  border-radius: 0.5rem;
  padding: 0.75rem 1rem 0.75rem;
}

.contact__label {
  font-size: var(--smaller-font-size);
  color: var(--title-color);
}

.contact__input {
  width: 100%;
  background-color: var(--input-color);
  color: var(--text-color);
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  border: none;
  outline: none;
  /* padding: .25rem .5rem .5rem 0; */
  padding-top: 0.25rem;
  border-bottom: 0.5px solid var(--text-color-light);
}

.contact__button {
  font-family: var(--body-font) !important;
  font-size: var(--normal-font-size) !important;
  font-weight: var(--font-medium) !important;
  border: none;
  outline: none;
}

/*==================== FOOTER ====================*/
.footer {
  padding-top: 2rem;
}

.footer__container {
  row-gap: 3.5rem;
}

.footer__bg {
  background-color: var(--first-color-second);
  padding: 2rem 0 3rem;
}

.footer__title {
  font-size: var(--h1-font-size);
  margin-bottom: var(--mb-0-25);
}

.footer__subtitle {
  font-size: var(--small-font-size);
}

.footer__links {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding-top: 0.5rem;
}

.footer__link:hover {
  color: var(--first-color-lighter);
}

.footer__socials {
  padding-top: 0.5rem;
}

.footer__social {
  font-size: 1.25rem;
  margin-right: var(--mb-1-5);
}

.footer__social:last-child {
  margin-right: 0;
}

.footer__social:hover {
  color: var(--first-color-lighter);
}

.footer__credits {
  font-size: var(--smaller-font-size);
  text-align: center;
  color: var(--text-color-light);
  margin-top: var(--mb-3);
}
.footer__heart {
  color: rgba(202, 46, 46, 0.864);
}

.footer__copy {
  font-size: var(--smaller-font-size);
  text-align: center;
  color: var(--text-color-light);
  margin-top: var(--mb-1-5);
}

.footer__title,
.footer__subtitle,
.footer__link,
.footer__social {
  color: #fff;
}

/*========== SCROLL UP ==========*/
.scrollup {
  position: fixed;
  right: 1rem;
  bottom: -20%;
  background-color: var(--first-color);
  opacity: 0.8;
  padding: 0.3rem;
  border-radius: 0.4rem;
  z-index: var(--z-tooltip);
  transition: 0.4s;
  display: flex;
  align-items: center;
}

.scrollup:hover {
  background-color: var(--first-color-alt);
}

.scrollup__icon {
  font-size: 1.5rem;
  color: var(--body-color);
}

/* Show scroll */
.show-scroll {
  bottom: 5rem;
}

/*========== SCROLL BAR ==========*/

::-webkit-scrollbar {
  width: 0.6rem;
  background-color: var(--scroll-bar-color);
  border-radius: 0.5rem;
}
::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb-color);
  border-radius: 0.5rem;
}

.project__modal--dialog ::-webkit-scrollbar {
  width: unset;
}

/* =========== snackbar =========== */

.MuiSnackbarContent-root {
  background-color: var(--first-color) !important;
  border-radius: 0.5rem !important;
  font-family: var(--body-font) !important;
  font-size: var(--normal-font-size) !important;
  margin-top: 2.5rem !important;
}

/* ================ Interests =========== */
.interests_container {
  overflow: initial !important;
  /* border: solid 2px red; */
}

.interests__content {
  padding: 0 1.5rem;
  grid-auto-rows: max-content 1fr; /* equal height for project data*/
  /* align-items: center; */
}

.interests__content-grid {
  display: none;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  row-gap: 3rem;
}

.interests__img {
  display: block;
  width: 265px;
  /* height: 100%; */
  border-radius: 0.5rem;
  justify-self: center;
  cursor: pointer;
}

.interests__data {
  display: flex;
  flex-direction: column;
  /* border: 2px solid red; */
}

.interests__title {
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-0-5);
}

.interests__description {
  margin-bottom: var(--mb-0-75);
}

.interests__button--div {
  display: flex;
  column-gap: 1rem;
  margin-top: auto;
  /* border: 2px solid red; */
}

/* ==========media queries ========= */
/* small screen */

@media screen and (max-width: 350px) {
  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }

  .nav__menu {
    padding: 2rem 0.25rem 2rem;
  }

  .nav__list {
    column-gap: 0;
  }

  .home__content {
    grid-template-columns: 0.25fr 3fr !important;
  }

  .home__blob {
    width: 180px;
  }

  .home__scroll {
    margin-top: 1.5rem !important;
  }
  .skills__title {
    font-size: var(--normal-font-size);
  }

  .project__modal--dialog ::-webkit-scrollbar {
    width: 0.2rem;
  }
}

@media screen and (max-width: 420px) {
  .home__content {
    padding-bottom: 2rem;
  }
}

@media screen and (min-width: 568px) {
  .home__content {
    grid-template-columns: max-content 1fr 1fr;
    /* column-gap: 2rem; */
  }

  .home__data {
    grid-column: initial;
  }

  .home__img {
    order: 1;
    justify-self: center;
  }

  .projects__swiper {
    display: none;
  }

  .interests__swiper {
    display: none;
  }
  /* .projects__data {
    margin-top: 1rem;
    
  } */

  .projects__content-grid {
    display: grid;
  }

  .interests__content-grid {
    display: grid;
  }

  /* .projects__content, */
  .project__container,
  .contact__container,
  .footer__container {
    grid-template-columns: repeat(2, 1fr);
  }

  /* .project__container {
    grid-template-columns: 0.8fr 1.2fr;
  } */

  .qualification__sections {
    display: grid;
    grid-template-columns: 0.9fr;
    justify-content: center;
  }

  .project__img {
    margin-top: 0;
  }

  .project__modal--dialog ::-webkit-scrollbar {
    width: 0.2rem;
  }
}

@media screen and (min-width: 768px) {
  /* .container {
    margin-left: auto;
    margin-right: auto;
  } */

  body,
  .body-div {
    margin: 0;
  }

  .section {
    padding: 6rem 0 2rem;
  }

  .section__subtitle {
    margin-bottom: 4rem;
  }

  /* .header {
    top: 0;
    bottom: initial;
  } */
  .header,
  .main,
  .footer__container {
    padding: 0 1rem;
  }

  .nav {
    height: calc(var(--header-height) + 1.5rem);
    column-gap: 1rem;
  }

  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: none;
  }

  .nav__list {
    display: flex;
    column-gap: 2rem;
  }

  .nav__menu {
    margin-left: auto;
  }

  .change-theme {
    margin: 0;
  }

  .home__container {
    row-gap: 5rem;
  }

  .home__content {
    padding-top: 5.5rem;
    column-gap: 2rem;
  }

  .home__blob {
    width: 270px;
  }

  .home__scroll-button {
    margin-left: 3rem;
  }

  .about__container {
    column-gap: 3rem;
  }

  .about__data {
    margin-top: 1rem;
  }

  .about__img {
    width: 300px;
  }

  .about__description {
    text-align: initial;
  }

  .about__buttons {
    justify-content: initial;
  }

  /* .about__container {
    grid-template-columns: repeat(2, auto);
  } */

  .qualification__tabs {
    justify-content: center;
  }

  .qualification__button {
    margin: 0 var(--mb-1);
  }

  .projects__content-grid {
    grid-template-columns: repeat(2, 1fr);
    /* column-gap: 3rem; */
    /* row-gap: 7rem; */
  }

  .interests__content-grid {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }

  /* .projects__data {
    max-width: 320px;
    justify-self: center;
  } */

  .projects__img {
    width: 100%;
  }

  .interests__img {
    width: 320px;
  }

  .project {
    text-align: initial;
  }

  .project__bg {
    background: none;
  }

  .project__container {
    background-color: var(--first-color-second);
    border-radius: 1rem;
    padding: 3rem 2.5rem;
    grid-template-columns: 1fr max-content;
    column-gap: 3rem;
  }

  .footer__container {
    grid-template-columns: 1fr 1fr auto;
  }

  .footer__bg {
    padding: 3rem 0 3.5rem;
  }

  .footer__links {
    flex-direction: row;
    column-gap: 2rem;
  }

  .footer__socials {
    justify-self: flex-end;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--mb-1-5);
    padding-right: var(--mb-1-5);
  }

  .header,
  .main,
  .footer__container {
    padding: 0;
  }

  .home__blob {
    width: 320px;
  }

  .home__content {
    column-gap: 4rem;
  }

  .home__scroll {
    padding-left: 2rem;
  }

  .about__container {
    grid-template-columns: repeat(2, auto);
    /* padding: 0 1.5rem !important; */
  }

  .about__img {
    width: 300px;
  }

  .about__img--long {
    display: block;
  }

  .about__img--short {
    display: none;
  }

  .about__data {
    margin-top: 0;
  }

  .qualification__sections {
    grid-template-columns: 1fr;
  }

  .projects__content {
    column-gap: 3rem;
  }

  .interests__content {
    column-gap: 3rem;
  }

  .project__container {
    padding: 3rem 3.5rem;
  }
  /* .projects__img {
    width: 90%;
  } */

  /* .projects__data {
    width: %;
    justify-self: center;
  } */

  .contact__form {
    width: 460px;
  }

  .contact__inputs {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .container {
    padding: 0;
  }

  .project__container {
    padding: 3rem 2.5rem;
  }

  .projects__content-grid {
    /* grid-template-columns: repeat(2, 1fr); */
    column-gap: 3rem;
    row-gap: 7rem;
  }
}
